import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Breadcrumbs from '../../components/pageProps/Breadcrumbs'

const About = () => {
  const location = useLocation()
  const [prevLocation, setPrevLocation] = useState('')
  useEffect(() => {
    setPrevLocation(location.state.data)
  }, [location])
  return (
    <div className="max-w-container items-center mx-auto px-4">
      <Breadcrumbs title="About" prevLocation={prevLocation} />
      <div className="pb-10 grid justify-items-start gap-4 ">
        <h1 className="max-w-[1200px] text-center  text-black mb-2 text-3xl ">
          About Us
        </h1>
        <h1 className="max-w-[1200px] text-justify  text-lightText mb-2 text-2xl ">
          Welcome to Shopycoholic, your premier destination for high-quality
          products and exceptional customer service. At Shopycoholic, we pride
          ourselves on curating a diverse selection of products that cater to
          your everyday needs and desires. From stylish fashion accessories to
          cutting-edge electronics and home essentials, we strive to offer a
          range of options to suit every lifestyle and taste. Our commitment to
          quality means that each item you find on our e-commerce platform has
          been carefully selected for its craftsmanship, durability, and value.
          Whether you're shopping for yourself or searching for the perfect
          gift, we invite you to explore our collection and experience the
          convenience of shopping with Shopycoholic.
        </h1>
        <h1 className="max-w-[1200px] text-center  text-black mb-2 text-3xl ">
          Our Mission
        </h1>
        <h1 className="max-w-[1200px] text-justify  text-lightText mb-2 text-2xl ">
          At Shopycoholic, our mission is to enrich the lives of our customers
          by providing access to premium products that enhance their daily
          experiences. We strive to be a trusted source for quality goods,
          offering a diverse range of items that meet the evolving needs and
          preferences of our clientele.
        </h1>
        <h1 className="max-w-[1200px] text-center  text-black mb-2 text-3xl ">
          Our Vision
        </h1>
        <h1 className="max-w-[1200px] text-justify  text-lightText mb-2 text-2xl ">
          Our vision at Shopycoholic is to become a leading e-commerce
          destination known for its commitment to excellence, innovation, and
          customer satisfaction. We aspire to create a seamless shopping
          experience that delights and inspires our customers, fostering
          long-lasting relationships built on trust and reliability.
        </h1>
        <h1 className="max-w-[1200px] text-center  text-black mb-2 text-3xl ">
          Objectives
        </h1>
        <h1 className="max-w-[1200px] text-lightText text-2xl">
          Aligned with our mission and vision, our objectives are threefold:
        </h1>
        <ul className="max-w-[1200px] text-justify text-2xl list-disc text-lightText  list-inside ">
          <li className="my-2">
            <b> Curate a Thoughtfully Selected Product Range: </b> We carefully
            curate our product selection to ensure that each item meets our
            rigorous standards of quality, craftsmanship, and value.{' '}
          </li>
          <li className="my-2">
            <b> Prioritize Customer Satisfaction:</b> We prioritize the
            satisfaction of our customers above all else, striving to exceed
            their expectations with exceptional service, timely delivery, and
            responsive support.{' '}
          </li>
          <li className="my-2">
            <b> Foster Continuous Improvement: </b> We are dedicated to
            continuously improving our operations, processes, and offerings to
            better serve our customers and adapt to changing market trends and
            demands.
          </li>
        </ul>
        <Link to="/shop">
          <button className="w-52 h-10 bg-primeColor text-white hover:bg-black duration-300 my-4">
            Continue Shopping
          </button>
        </Link>
      </div>
    </div>
  )
}

export default About
